<template>
  <a-modal
    :width="width + 'px'"
    v-model:visible="state.visible"
    :title="$t('warehouse.upload_product_labels')"
    :centered="true"
    :mask-closable="false"
    :closable="false"
    :ok-text="$t('common.save')"
    :confirm-loading="state.confirmLoading"
    @cancel="close"
    @ok="handleConfirm"
  >
    <a-row :gutter="[8, 8]">
      <a-col :span="24">{{ product.productName }}</a-col>
      <a-col :span="24">{{ $t('warehouse.product_no') }}: {{ product.productNo }}</a-col>
      <a-col :span="24">SESKU: {{ product.seSku }}</a-col>
    </a-row>
    <a-row class="mt-3" :gutter="[24, 24]" type="flex" justify="center">
      <!-- 默认图 -->
      <a-col v-for="(item, index) in labels" :key="index">
        <div class="file-container">
          <div class="name-row mb-1">
            <p class="name-row-text">{{ item.name }}</p>
          </div>
          <div class="mb-4">{{ $t('warehouse.can_identification_content') }}: {{ item.code }}</div>
          <CFileDisplay :fileUrl="item.imgUrl" />
          <div style="height: 16px;"> </div>
        </div>
      </a-col>
      <!-- 用户新添加 -->
      <a-col v-for="(item, index) in state.fileList" :key="item.key">
        <div class="file-container">
          <div class="name-row mb-1">
            <a-row :gutter="4" v-if="item.isEdit">
              <a-col flex="1">
                <a-input v-model:value="item.name" size="small" :maxLength="64" @blur="handleConfirmName(item)"></a-input>
              </a-col>
              <a-col>
                <a-button type="primary" class="ml-1" size="small" @click="handleConfirmName(item)">{{ $t('common.confirm') }}</a-button>
              </a-col>
            </a-row>
            <template v-else>
              <p class="name-row-text">{{ item.name }}</p>
              <EditOutlined class="text-primary ml-1" style="cursor: pointer;" @click="item.isEdit = true" />
            </template>
          </div>
          <div class="mb-4">{{ $t('warehouse.can_identification_content') }}: {{ item.code }}</div>
          <CFileDisplay :fileUrl="item.imgUrl" />
          <div style="height: 16px;">
            <a-tooltip placement="right" :title="$t('common.delete')">
              <span class="delete-label" @click="deleteLabel(index)">
                <DeleteOutlined />
              </span>
            </a-tooltip>
          </div>
        </div>
      </a-col>
      <a-col v-if="totalLength < maxLength && showUpload">
        <div class="upload-container">
          <a-upload
            name="avatar"
            list-type="picture-card"
            accept=" .jpeg,.jpg,.png,.pdf"
            :show-upload-list="false"
            :customRequest="handleUpdate"
          >
            <loading-outlined v-if="state.uploadLoading" style="font-size: 28px; color: #aaa"></loading-outlined>
            <plus-outlined v-else style="font-size: 28px; color: #aaa"></plus-outlined>
            <div class="ant-upload-text">{{ $t('common.upload') }}</div>
          </a-upload>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, watch } from 'vue';
import { Button, Card, Col, Input, Modal, Row, Tooltip, Typography, Upload, message } from 'ant-design-vue';
import CFileDisplay from './CFileDisplay.vue';
import { useI18n } from "vue-i18n/index";
import { uploadImg } from "@/api/modules/common/index";
import { randomString } from '@/utils/general.js';
import { createProductLabel } from '@/api/modules/product/index.js';

export default defineComponent({
  emits: ['confirm', 'update:visible'],
  components: {
    AModal: Modal,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ATypographyParagraph: Typography.Paragraph,
    AUpload: Upload,
    ATooltip: Tooltip,
    AInput: Input,
    AButton: Button,
    CFileDisplay,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => ({
        productId: null,
        productName: null,
        productNo: null,
        seSku: null,
      }),
      required: true,
    },
    width: {
      type: Number,
      default: 400,
    },
    // 默认展示的图
    labels: {
      type: Array,
      default: () => ([])
    },
    // 是否显示上传按钮
    showUpload: {
      type: Boolean,
      default: true,
    },
    // 最多展示数量
    maxLength: {
      type: Number,
      default: 1
    }
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      visible: false,
      confirmLoading: false,
      uploadLoading: false,
      fileList: [],
    });

    const totalLength = computed(() => {
      return props.labels.length + state.fileList.length;
    })

    const handleConfirm = async () => {
      try {
        let labels = state.fileList.map(item => ({
          name: item.name,
          code: item.code,
          imgUrl: item.imgUrl,
        }));
        if (!labels.length) {
          message.error(vueI18n.t('common.p0_is_required', [vueI18n.t('warehouse.product_label')]));
          return;
        }
        if (labels.length === 1) {
          const data = {
            productId: props.product.productId,
            name: labels[0].name,
            code: labels[0].code,
            imgUrl: labels[0].imgUrl,
          }
          state.confirmLoading = true;
          await createProductLabel(data);
        }
        message.success(vueI18n.t('common.succeed'));
        emit('confirm', { productId: props.product.productId, labels });
        close();
      } catch (error) {
      } finally {
        state.confirmLoading = false;
      }
    }

    const handleConfirmName = (item) => {
      if (!item.name) {
        item.name = item.oldName;
      }
      item.isEdit = false;
    }

    const deleteLabel = (index) => {
      state.fileList.splice(index, 1);
    }

    const handleUpdate = (file) => {
      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", 2);
      uploadImg(parameter).then(({ result }) => {
        if (Array.isArray(result) && result.length > 0) {
          let fileResult = result[0];
          if (fileResult.parsingCode) {
            state.fileList.push({
              name: fileResult.fileName,
              oldName: fileResult.fileName,
              code: fileResult.parsingCode,
              imgUrl: fileResult.url,
              key: randomString(6),
              isEdit: false,
            });
          } else {
            message.error(vueI18n.t("warehouse.the_code_cannot_be_recognized_please_change_the_file"));
          }
        }
      }).catch(() => {}).finally(() => {
        state.uploadLoading = false;
      });
    }

    const close = () => {
      emit('update:visible', false);
    }
    
    watch(() => props.visible, (value) => {
      state.visible = value;
      if (value) {
        state.fileList = [];
      }
    }, { immediate: true });

    return {
      state,
      totalLength,
      handleConfirm,
      handleConfirmName,
      deleteLabel,
      handleUpdate,
      open,
      close,
    }
  }
})
</script>

<style lang="less" scoped>
.file-container {
  width: 300px;
  height: 200px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
}

.name-row {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  &-text {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.delete-label {
  font-size: 15px;
  color: #d9d9d9;
  cursor: pointer;
  :hover {
    color: @primary-color;
  }
}

.upload-container {
  width: 300px;
  height: 200px;
  .upload-button-svg {
    font-size: 30px;
    color: #c2c0c0;
  }
  :deep(.ant-upload) {
    width: 300px !important;
    height: 200px !important;
    box-sizing: border-box;
    margin: 0;
  }
}
</style>