<template>
  <a-upload name="uploadFile"
            :customRequest="handleUpload"
            :accept="accept"
            :showUploadList="false">
    <a-button v-if="files.length<1"
              type="link"
              size="large"
             :loading="uploadLoading"
            >
              <UploadOutlined />
            </a-button>
  </a-upload>
  <div style="position:relative;display: flex;flex: 0;">
    <div v-for="(item, index) in files"
         :key="index"
         class="p-1 "
         style="position:relative;">
      <CFileDisplay :fileUrl="item.url"></CFileDisplay>
      <a-tooltip placement="right">
        <template #title>{{$t('common.delete')}}</template>
        <span class="delete-label"
              @click="deleteFile(index)">
          <DeleteOutlined />
        </span>
      </a-tooltip>
    </div>
  </div>

</template>
<script>
import { reactive, toRefs, watchEffect, } from "vue";
import CImage from "../../../components/CImage.vue"
import {
  uploadFile
} from "@/api/modules/transportation/index";
import CFileDisplay from "../../../components/CFileDisplay.vue";
import { useI18n } from "vue-i18n/index";
import urlHelper from "@/utils/urlHelper";

import {
  Row, Col, Upload,
  Tooltip,
  Select, Button, Form, message
} from "ant-design-vue";
export default ({
  name: "UploadExtraTag",
  components: {
    CFileDisplay,
    CImage,
    ATooltip: Tooltip,
    AUpload: Upload,
    AImage: Image,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
  },
  props: {
    fileUrl: {
      required: true,
      default: null,
    },
    functionModule: {
      required: false,
      default: 26,
    },
    accept: {//传入后缀,不传类型,用后缀做的验证
      required: false,
      default: '',
    },
  },
  setup (props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const state = reactive({
      uploadLoading: false,
      files: [],
    });

    watchEffect(() => {
      if (props?.fileUrl) {
        state.files = [{ url: props.fileUrl }];
      } else {
        state.files = [];
      }
    })

    const isVerifiedFileType = (fileName) => {
      let fileExtension = urlHelper.getUrlExtension(fileName)?.toLowerCase();
      let arrayAccept = props.accept.split(',');
      return (
        arrayAccept &&
        arrayAccept.findIndex(
          (item) => item == fileExtension
        ) >= 0
      );
    }

    const handleUpload = (file) => {
      if (props.accept && !isVerifiedFileType(file.file.name)) {
        message.error(vueI18n.t("common.the_format_of_the_uploaded_file_is_not_supported"));
        return
      }

      state.uploadLoading = true;
      let parameter = new FormData();
      parameter.append("file", file.file);
      parameter.append("functionModule", props.functionModule * 1);
      uploadFile(parameter).then((res) => {
        if (res.result && res.result.length > 0) {
          emit('update:fileUrl', res.result[0].url)
        }
      }).finally(() => {
        state.uploadLoading = false;
      });
    }

    const deleteFile = async (itemIndex) => {
      emit('update:fileUrl', null)
    }

    return {
      ...toRefs(state),
      handleUpload,
      deleteFile,
    };
  }
})
</script>

<style lang="less" scoped>
.file-imgs {
  width: 50px;
  height: 50px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  :deep(.ant-image img) {
    max-height: 40px;
  }
}

.delete-label {
  position: absolute;
  top: 90%;
  left: 40%;
  font-size: 15px;
  color: #d9d9d9;
  :hover {
    color: #04befe;
    //font-size: 20px;
  }
}
</style>