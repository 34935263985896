<template>
  <!-- 添加产品modal -->
  <a-modal
    width="900px"
    :visible="visibleProductModal"
    :centered="true"
    :maskClosable="false"
    :ok-text="$t('common.confirm')"
    :cancel-text="$t('common.cancel')"
    @cancel="close"
    @ok="handleOK"
    :title="$t('warehouse.select_product')"
  >
    <a-form layout="inline">
      <a-form-item class="mb-1">
        <SearchProduct ref="refSearchProduct"></SearchProduct>
      </a-form-item>
      <a-form-item class="mb-1">
        <a-button
          type="primary"
          :loading="data.loading"
          @click="handleSearchProducts"
          >{{ $t("common.query") }}</a-button
        >
      </a-form-item>
    </a-form>
    <a-table
      :columns="modalColumns"
      :data-source="data.list"
      :scroll="{ x: 500, y: 1000 }"
      :pagination="false"
      size="small"
      :loading="data.loading"
      :rowKey="
        (record, index) => {
          return index + record.productName;
        }
      "
    >
      <template #productNameAndSeSku>
        <span>
          {{ $t("warehouse.product_name") }}/{{ $t("common.product_no") }}
        </span>
      </template>
      <template #count>
        <span>
          {{ $t("logistics.usable_inventory") }}
        </span>
      </template>

      <template #productNameAndSeSkuCustom="{ record }">
        <a-space>
          <div class="table-list-img-common">
            <c-image :src="record.imgUrl"></c-image>
          </div>
          <div>
            <div>{{ record.productName }}</div>
            <div>{{ record.productNo }}</div>
          </div>
        </a-space>
      </template>

      <template #addButtonCustom="{ record }">
        <a-button v-if="existsProduct(record)" disabled>{{
          $t("common.added")
        }}</a-button>
        <div v-else>
          <a-button
            @click="handleAddTempList(record)"
            :disabled="parseInt(record.count) <= 0"
            :type="isSelected(record) ? 'primary' : null"
            >{{ $t("common.add") }}</a-button
          >
        </div>
      </template>
      <template #footer>
        <a-row type="flex" justify="center" align="middle">
          <a-col>
            <CPager
              @handlePage="handlePage"
              :showLessItems="true"
              :pageSizeOptions="['5', '10', '30']"
              :page-data="data.pageData"
            ></CPager>
          </a-col>
        </a-row>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { reactive, ref, watch } from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Form,
  Modal,
  Space,
  Spin,
} from "ant-design-vue";
import CPager from "../../../components/CPager.vue";
import { getInventoryProductList } from "@/api/modules/consignment/index";
import CImage from "../../../components/CImage.vue";
import SearchProduct from "../../../components/SearchProduct.vue";

export default {
  name: "AddProductModal",
  components: {
    CImage,
    CPager,
    SearchProduct,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpace: Space,
    ASpin: Spin,
  },
  props: {
    visibleProductModal: {
      type: Boolean,
      required: true,
    },
    warehouseId: {
      required: true,
    },
    productIds: {
      // required: true,
      default: [],
    },
  },
  emits: ["update:visibleProductModal", "addProducts"],
  setup(props, { emit }) {
    watch(
      () => props.visibleProductModal,
      (newValue, oldV) => {
        if (newValue) {
          handleGetProducts();
          data.addList = [];
        }
      }
    );

    const refSearchProduct = ref();

    const data = reactive({
      loading: false,
      list: [],
      addList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5,
      },
    });

    const modalColumns = [
      {
        slots: {
          title: "productNameAndSeSku",
          customRender: "productNameAndSeSkuCustom",
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 150,
      },
      {
        dataIndex: "count",
        slots: {
          title: "count",
        },
        width: 150,
      },
      {
        title: "",
        dataIndex: "addButton",
        slots: {
          customRender: "addButtonCustom",
        },
        width: 150,
      },
    ];

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      handleGetProducts();
    };

    const handleSearchProducts = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      handleGetProducts();
    };

    const handleGetProducts = async () => {
      data.loading = true;
      const searchData = {
        warehouseId: props.warehouseId,
        productId: refSearchProduct.value
          ? refSearchProduct.value.selectedProductId
          : "",
        ...data.pageData,
        isLabel:true
      };
      getInventoryProductList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.list = items ?? [];
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
            data.list = [];
          }
          data.loading = false;
        })
        .catch((e) => {
          data.loading = false;
        });
    };

    const existsProduct = (record) => {
      return props.productIds?.findIndex((x) => x == record.productId) != -1;
    };

    const handleAddTempList = (record) => {
      if (data.addList.findIndex((x) => x.productId == record.productId) >= 0) {
        data.addList = data.addList.filter(
          (x) => x.productId != record.productId
        );
      } else {
        data.addList.push(record);
      }
    };

    const isSelected = (record) => {
      return (
        data.addList.findIndex((x) => x.productId == record.productId) >= 0
      );
    };

    const handleOK = async (record) => {
      emit("addProducts", data.addList);
      close();
    };

    const close = () => {
      emit("update:visibleProductModal", false);
    };

    return {
      refSearchProduct,
      modalColumns,
      close,

      data,
      existsProduct,

      handlePage,
      handleSearchProducts,
      handleGetProducts,
      handleOK,
      handleAddTempList,
      isSelected,
    };
  },
};
</script>

<style lang="less" scoped></style>
