<template>
  <Content>
    <template #contentBody>
      <a-row>
        <a-col :span="24">
          <a-steps :current="1" style="max-width: 1800px" size="small">
            <a-step :title="$t('warehouse.create_allot_to_storage_of_plan')" />
            <a-step :title="$t('warehouse.set_packing_plan')" />
            <a-step :title="$t('warehouse.confirm_transfer_plan')" />
            <a-step :title="$t('common.completed')" />
          </a-steps>
        </a-col>
      </a-row>
      <div class="mt-3 mb-3">
        <p>
          {{ $t("warehouse.consignment_warehouse_code") }}:
          {{ state?.warehouse?.warehouseNo }}
          <span class="ml-5" v-if="state.warehouseInfo"
            >{{ $t("logistics.contacts") }}:
            {{ getLinkman(state.warehouseInfo) }}</span
          >
        </p>
      </div>

      <div class="table">
        <a-button
          type="primary"
          @click="handleShowAddBoxModal"
          class="mb-1"
          ghost
          >{{ $t("warehouse.add_box") }}</a-button
        >
        <a-table
          :columns="columns"
          :data-source="state?.boxList"
          size="small"
          style="min-height: 223px"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          :pagination="false"
        >
          <template #materialsType="{ record }">
            {{ record.materialTypeCnName }}/{{ record.materialTypeEnName }}
          </template>

          <template #outerDiameterSize="{ record }">
            <span v-if="!record.packMaterialLength">-</span>
            <span v-else>
              {{ record.packMaterialLength }}x{{ record.packMaterialWidth }}x{{
                record.packMaterialHeight
              }}
              cm
            </span>
          </template>

          <template #weight="{ record }">
            <span
              v-if="
                record.packMaterialWeight 
              "
            >
              {{ gToKg(record.packMaterialWeight) }} kg
            </span>
            <span v-else> - </span>
          </template>

          <template #price="{ record }">
            {{ record.currencySymbol }}
            {{ $filters.amountToFixed2(record.price) }}
          </template>

          <template #totalWeight="{ record }">
            <span v-if="record.isSupported && record.totalWeightPack">
              <span v-if="record.totalWeightPack">
                {{ gToKg(record.totalWeightPack) }} kg
              </span>
              <span v-else>-</span>
            </span>
            <span v-else>
              {{ $t("warehouse.not_applicable") }}
            </span>
          </template>

          <template #operateCustom="{ record }">
            <a-button @click="handleRemoveBox(record)">{{
              $t("common.remove")
            }}</a-button>
          </template>

          <template #footer v-if="totalBox > 0">
            <a-row :gutter="[64, 8]">
              <a-col
                >{{ $t("common.total") }}{{ totalBox
                }}{{ $t("warehouse.box") }}</a-col
              >
              <a-col>{{ $t("common.p0_products", [totalProduct]) }}</a-col>
              <a-col>{{ $t("common.total") }}{{ totalUnit }} Unit</a-col>
            </a-row>
          </template>

          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="record.innerData"
              :pagination="false"
              :row-key="(record, index) => index"
              size="small"
            >
              <template #productInfo="{ record }">
                <a-space>
                  <div class="table-list-img-common">
                    <c-image :src="record.imgUrl"></c-image>
                  </div>
                  <div>
                    <div>{{ record.productName }}</div>
                    <div>{{ record.productNo }}</div>
                  </div>
                </a-space>
              </template>

              <template #product_size="{ record }">
                <span v-if="record?.packingLength">
                  {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                    record?.packingHeight
                  }}
                  cm
                </span>
                <span v-else>-</span>
              </template>

              <template #weight="{ record }">
                <span v-if="record?.packingWeight">
                  {{ gToKg(record?.packingWeight) }} kg
                </span>
                <span v-else>-</span>
              </template>

              <template #encasement_count="{ record }">
                <div v-if="record.isEditNum">
                  <a-row :gutter="[8, 8]">
                    <a-col>
                      <a-input-number
                        v-model:value="record.numEdit"
                        :min="1"
                        :precision="0"
                        :max="record.num"
                      ></a-input-number>
                    </a-col>
                    <a-col>
                      <a-button
                        @click="handleConfirmUpdateProductCount(record)"
                        type="primary"
                        ghost
                      >
                        {{ $t("common.save") }}
                      </a-button>
                    </a-col>
                  </a-row>
                </div>
                <div v-else>
                  {{ record.numEdit || record.num }} Unit
                  <a href="javascript:;">
                    <EditOutlined
                      @click="
                        () => {
                          record.isEditNum = true;
                          record.numEdit = record.numEdit || record.num;
                        }
                      "
                    />
                  </a>
                </div>
              </template>

              <template #volume="{ record }">
                <span v-if="record?.packingVolume">
                  {{ cmCubicToM(record?.packingVolume) }} m<sup>3</sup>
                </span>
                <span v-else>-</span>
              </template>

              <template #product_label="{ record }">
                <div v-if="record.isEditLabel">
                  <a-select
                    :disabled="!record?.count || record?.count <= 0"
                    @change="handleChangeRowLabel"
                    :loading="record.loading"
                    :defaultValue="0"
                    v-model:value="record.selectedLabelId"
                    :showSearch="true"
                    style="width: 150px"
                    optionFilterProp="search-key"
                  >
                    <a-select-option
                      :value="0"
                      :currentLabels="null"
                      :record="record"
                    >
                      {{ $t("warehouse.not_select_service") }}
                    </a-select-option>
                    <a-select-option
                      v-for="(value, key) in record.labels"
                      :key="key"
                      :value="value.id"
                      :currentLabels="value"
                      :record="record"
                      :search-key="value.name"
                      :title="value.name"
                    >
                      {{ value.name }}
                    </a-select-option>
                  </a-select>
                  <a-button
                    type="link"
                    size="large"
                    @click.stop="handleShowAddProductLabel(record)"
                  >
                    <UploadOutlined />
                  </a-button>
                  <CProductLabelUpload
                    v-model:visible="record.labelUploadVisible"
                    :product="record.product"
                    @confirm="(e) => handleUploadSuccessful(e, record)"
                  />
                  <a-button
                    @click="record.isEditLabel = false"
                    type="primary"
                    ghost
                  >
                    {{ $t("common.save") }}
                  </a-button>
                </div>
                <div v-else>
                  <span v-if="record.label">
                    {{ $t("warehouse.label_serve") }}:
                    {{ record?.label?.name }}
                  </span>
                  <span v-else>
                    {{ $t("warehouse.unlabeled_service") }}
                  </span>
                  <a href="javascript:;" class="ml-1">
                    <EditOutlined
                      @click="
                        () => {
                          record.isEditLabel = true;
                        }
                      "
                    />
                  </a>
                </div>
              </template>
              <template #extra_tags="{ record }">
              <div class="mt-2 mb-2">
                <UploadExtraTag 
                v-model:fileUrl="record.otherLabelUrl"
                                    accept=".jpeg,.jpg,.png,.pdf"
                ></UploadExtraTag>
              </div>
            </template>
            </a-table>
          </template>
        </a-table>
      </div>

      <div class="mt-3" v-if="state.boxList?.length > 0">
        <div>
          {{ $t("warehouse.product_information_summary") }}
        </div>
        <a-table
          :columns="productSummaryColumns"
          :data-source="productSummary"
          :pagination="false"
          :row-key="(record, index) => index"
          size="small"
        >
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.imgUrl"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #product_size="{ record }">
            <span v-if="record?.packingLength">
              {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                record?.packingHeight
              }}
              cm
            </span>
            <span v-else>-</span>
          </template>

          <template #weight="{ record }">
            <span v-if="record?.packingWeight">
              {{ gToKg(record?.packingWeight) }} kg
            </span>
            <span v-else>-</span>
          </template>

          <template #current_warehouse_usable_inventory="{ record }">
            {{ record?.count }} Unit
          </template>

          <template #encasement_count="{ record }">
            <span :class="record.allotNum > record?.count ? 'text-error' : ''">
              {{ record.allotNum }} Unit
            </span>
          </template>
        </a-table>
      </div>

      <!-- button创建计划 -->
      <div class="mt-3 mb-5">
        <a-row type="flex" justify="end">
          <a-col class="mr-3">
            <a-button type="primary" @click="handleBackStep" ghost>{{
              $t("common.back_step")
            }}</a-button>
          </a-col>
          <a-col class="mr-3">
            <a-button
              type="primary"
              :loading="state.createLoading"
              @click="handleNextStep"
              >{{ $t("common.next_step") }}</a-button
            >
          </a-col>
        </a-row>
      </div>

      <!-- 添加箱Modal -->
      <AddBoxModal
        v-model:visible="addBoxModal.visible"
        :warehouseId="state?.warehouse?.warehouseId"
        @createBoxInfo="handleCreateBoxInfo"
      ></AddBoxModal>
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted,  computed } from "vue";
import Content from "../../components/Content.vue";
import {
  Row,
  Col,
  Steps,
  Select,
  Table,
  Input,
  InputNumber,
  Button,
  Form,
  Modal,
  Space,
  message,
  Typography,
  Tooltip,
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CPager from "../../components/CPager.vue";
import { useRoute, useRouter, onBeforeRouteLeave } from "vue-router";
import { useTab } from "../../../hooks/tabs/index";
import CImage from "../../components/CImage.vue";
import {  gToKg, getLinkman, cmCubicToM } from "../../../utils/general";
import SearchProduct from "../../components/SearchProduct.vue";
import CProductLabelUpload from "../../components/CProductLabelUpload.vue";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { allotPlanKeys } from "../../../consts/index";
import { getUserWarehouseWithStock } from "@/api/modules/common/index";
import AddBoxModal from "./components/AddBoxModal";
import { getInventoryProductList } from "../../../api/modules/consignment/allot";
import { cloneDeep } from "lodash-es";
import UploadExtraTag from "./components/UploadExtraTag.vue";

export default {
  name: "consignment_allot_set_box",
  components: {
    UploadExtraTag,
    AddBoxModal,
    CProductLabelUpload,
    CImage,
    Content,
    CPager,
    SearchProduct,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ATypographyParagraph: Typography.Paragraph,
    ATooltip: Tooltip,
  },
  setup() {
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const { delVisitedRoute, visitedRoutes } = useTab();

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: () => vueI18n.t("warehouse.packing_material_name"),
        dataIndex: "packMaterialName",
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.materials"),
        slots: {
          customRender: "materialsType",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.outer_diameter_size"),
        slots: {
          customRender: "outerDiameterSize",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.material_fee"),
        slots: {
          customRender: "price",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_after_packing"),
        slots: {
          customRender: "totalWeight",
        },
        width: 150,
      },
      {
        title: "",
        slots: {
          customRender: "operateCustom",
        },
        width: 100,
      },
    ];

    const innerColumns = [
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        slots: {
          customRender: "volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.product_label"),
        slots: {
          customRender: "product_label",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.extra_tags"),
        slots: {
          customRender: "extra_tags",
        },
        width: 100,
      },
    ];

    const state = reactive({
      warehouse: null,
      warehousesList: [],
      boxList: [],
    });

    const getWarehouses = () => {
      getUserWarehouseWithStock().then(({ result }) => {
        if (result) {
          state.warehousesList = result;
          state.warehouseInfo = state.warehousesList?.find(
            (item) => item.warehouseId === state.warehouse.warehouseId
          );
        }
      });
    };

    //----------------------添加箱 start--------------------------------------------------
    const addBoxModal = reactive({
      visible: false,
    });
    const handleShowAddBoxModal = () => {
      addBoxModal.visible = true;
    };

    const handleCreateBoxInfo = (productList, selectMaterial, boxCount) => {
      for (let index = 0; index < boxCount; index++) {
        selectMaterial.innerData = cloneDeep(productList);
        selectMaterial.uniqueId = `${Date.now()}${Math.random()
          .toString(36)
          .substring(7)}`;
        state.boxList.push(cloneDeep(selectMaterial));
      }
    };
    //----------------------添加箱 end--------------------------------------------------

    //----------------------更新产品标签 start------------------------------------------------
    const handleChangeRowLabel = (value, option) => {
      option.record.label = option.currentLabels;
    };

    const handleShowAddProductLabel = (record) => {
      record.product = {
        productId: record.productId,
        productName: record.productName,
        productNo: record.productNo,
        seSku: record.seSku,
      };
      record.labelUploadVisible = true;
    };

    const handleUploadSuccessful = (e, record) => {
      record.loading = true;
      const searchData = {
        warehouseId: state.warehouse.warehouseId,
        productId: e.productId,
        skipCount: 0,
        maxResultCount: 1,
        needNoneInventory: true,
        isLabel: true,
      };
      getInventoryProductList(searchData)
        .then(({ result }) => {
          if (
            result.items &&
            result.items?.length == 1 &&
            result.items[0]?.productId == e.productId
          ) {
            record.labels = result.items[0]?.labels;
          }
        })
        .finally(() => {
          record.loading = false;
        });
    };

    //------------------------更新产品标签 end----------------------------------------------

    //------------------------修改装修数量 start----------------------------------------------
    const handleConfirmUpdateProductCount = (record) => {
      if (record.numEdit > record.num) {
        message.error(
          vueI18n.t(
            "warehouse.the_modified_number_must_be_less_than_or_equal_to_p0",
            [record.num]
          )
        );
        return;
      } else {
        record.isEditNum = false;
      }
    };
    //------------------------修改装修数量 end----------------------------------------------

    //------------------------产品信息汇总 start----------------------------------------------
    const productSummary = computed(() => {
      let list = [];
      state.boxList?.forEach((x) => {
        x.innerData?.forEach((i) => {
          let product = list.find((l) => l.productId == i.productId);
          if (product) {
            product.allotNum += i.numEdit || i.num;
          } else {
            i.allotNum = i.numEdit || i.num;
            list.push(i);
          }
        });
      });
      return list;
    });

    const productSummaryColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 80,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_size"),
        slots: {
          customRender: "product_size",
        },
        width: 80,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.current_warehouse_usable_inventory"),
        slots: {
          customRender: "current_warehouse_usable_inventory",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.allot_amount"),
        slots: {
          customRender: "encasement_count",
        },
        width: 100,
      },
    ];

    //------------------------产品信息汇总 end----------------------------------------------

    //------------------------统计箱数据 start----------------------------------------------
    const totalBox = computed(() => {
      return state.boxList?.length;
    });

    const totalProduct = computed(() => {
      let productIds = [];
      state.boxList?.forEach((x) => {
        x.innerData?.forEach((i) => {
          productIds.push(i.productId);
        });
      });
      return [...new Set(productIds)]?.length ?? 0;
    });

    const totalUnit = computed(() => {
      let totalUnit = 0;
      state.boxList?.forEach((x) => {
        x.innerData?.forEach((i) => {
          totalUnit += i.numEdit || i.num;
        });
      });
      return totalUnit;
    });

    //------------------------统计箱数据 end----------------------------------------------

    const handleRemoveBox = (record) => {
      state.boxList = state.boxList.filter(
        (x) => x.uniqueId != record.uniqueId
      );
    };

    const _setCachePageData = (cacheData) => {
      setObjectSessionStorage(allotPlanKeys.setBoxPageData, cacheData);
      setObjectSessionStorage(allotPlanKeys.confirmPageData, cacheData);
    };

    const _getCachePageData = () => {
      let cacheData = getObjectSessionStorage(allotPlanKeys.setBoxPageData);
      if (cacheData) {
        Object.assign(state, cacheData.state);
      }
    };

    const handleBackStep = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_allot_create" });
    };

    const handleNextStep = async () => {
      if (state.boxList?.length == 0) {
        return message.error(vueI18n.t("warehouse.please_add_a_box_first"));
      }

      let isEdit = state.boxList.find((x) =>
        x.innerData.find((f) => f.isEditNum || f.isEditLabel)
      );
      if (isEdit) {
        return message.error(
          vueI18n.t(
            "warehouse.please_save_the_packaging_quantity_or_label_service_being_modified_first"
          )
        );
      }
      let product = productSummary.value?.find((x) => x.allotNum > x.count);
      if (product) {
        return message.error(
          vueI18n.t(
            "warehouse.the_product_with_SESKU_p0_exceeds_available_inventory_please_modify_the_quantity",
            [product.seSku]
          )
        );
      }

      let cacheData = {
        state,
        productSummary: productSummary.value,
        totalBox: totalBox.value,
        totalProduct: totalProduct.value,
        totalUnit: totalUnit.value,
      };
      _setCachePageData(cacheData);
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_allot_confirm" });
    };

    onMounted(async () => {
      let warehouse = route.params;
      if (warehouse?.warehouseId && warehouse?.warehouseNo) {
        state.warehouse = warehouse;
        getWarehouses();
      } else {
        _getCachePageData();
      }
    });

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = [
        "consignment_allot_set_box",
        "consignment_allot_confirm",
      ];
      //要去页面不是调拨计划相关页面
      if (!cacheRoutes.includes(to.name)) {
        //已经存在的tbs中也没有调拨计划相关页面
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.name)) >
          -1;
        //就清除清除缓存
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(allotPlanKeys.setBoxPageData);
          removeObjectSessionStorage(allotPlanKeys.confirmPageData);
        }
      }
      next();
    });

    return {
      columns,
      innerColumns,

      getLinkman,
      gToKg,
      cmCubicToM,

      totalBox,
      totalProduct,
      totalUnit,

      state,
      addBoxModal,
      handleShowAddBoxModal,
      handleCreateBoxInfo,

      handleRemoveBox,

      handleChangeRowLabel,
      handleShowAddProductLabel,
      handleUploadSuccessful,

      handleConfirmUpdateProductCount,

      productSummaryColumns,
      productSummary,

      handleNextStep,
      handleBackStep,
    };
  },
};
</script>

<style lang="less" scoped></style>
